<template>
  <v-text-field
    v-model="_value"
    :type="isFocused ? 'number' : 'text'"
    min="0"
    inputmode="numeric"
    @blur="
      isFocused = false;
      $emit('input-lose-focus');
    "
    @focus="isFocused = true"
    @keypress="isNumberKey($event)"
    @keydown.48="handleZero"
    @keydown.96="handleZero"
    @keydown.delete="handleDelete($event)"
    v-bind="$attrs"
    v-on="$listeners"
    :max="max"
  >
    <template v-if="append" v-slot:append-outer>
      <v-icon
        v-if="appendIcon"
        small
        role="button"
        @click="helpModal(append)"
        class="ml-1 mt-1"
        >$infoIconGrey</v-icon
      >
      <span v-else>{{ append }}</span>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "InputCurrency",
  props: {
    value: {
      default: "",
      required: true
    },
    max: {
      type: Number,
      default: 10000000,
      required: false
    },
    color: {
      type: String,
      default: "primary",
      required: false
    },
    height: {
      type: Number,
      default: 20,
      required: false
    },
    append: {
      default: false
    },
    appendIcon: {
      default: false
    }
  },
  data() {
    return {
      isFocused: false
    };
  },
  computed: {
    // TODO: If possible emit changes after user completed input instead of realtime.
    _value: {
      get: function() {
        if (!this.isFocused && this.value >= 0) {
          return this.priceFormat(this.value);
        }
        return this.value;
      },
      set: function(modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        if (isNaN(newValue)) {
          newValue = "";
        }
        this.$emit("input", newValue);
        this.$emit("input-changed");
      }
    }
  },
  methods: {
    handleDelete(event) {
      if (this.value === 0) event.preventDefault();
    },
    handleZero(event) {
      if (this.value === 0) event.preventDefault();
    },
    isNumberKey: function(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57 || keyCode == 46) {
        event.preventDefault();
      }
    },
    helpModal(component) {
      this.$store.commit("showModal", { component });
    } 
  }
};
</script>
